import logo from './logo.svg';
import record from './record.svg';
import background from './lpbg.png';
import merch from './images/merch.png';
import studio from './images/studio.png';
import label from './images/label.png';
import events from './images/events.png';

import './App.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faSpotify} from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';


function App() {
  return (
    <div className="App">
      <header className="App-header" style={{ backgroundImage: `url(${background})` }}>
        <div className='Main-row'>
          <div className='feature-container'>
            <img src={studio} className="feature" alt="studio" />
            <img src={merch} className="feature" alt="merch" />
          </div>
          <div className='image-container'>
            <img src={record} className="App-record" alt="record" />
            <img src={logo} className="App-logo" alt="logo" />
          </div>
          <div className='feature-container'>
            <img src={events} className="feature" alt="events" />
            <img src={label} className="feature" alt="label" />
          </div>
        </div>
        <div className='secondary-row'>
          <div className='spotify'>
            <iframe 
              style={{ borderRadius: '12px' }} 
              src="https://open.spotify.com/embed/album/3WHOt5SxfMGXUrfS88tWEP?utm_source=generator&theme=0" 
              width="100%" 
              height="352" 
              frameBorder="0" 
              allowFullScreen 
              allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" 
              loading="lazy">
            </iframe>
          </div>
          <div className='text-copy'>
            <p>
              Sitio web en construcción.
            </p>
            <div className='email'>
              <a href="mailto:info@lbrlatam.com">
                <FontAwesomeIcon icon={faEnvelope} size="1x" style={{ marginRight: '8px' }} />
              </a>
              <p>info@lbrlatam.com</p>
            </div>
            <p className='frase'>Visítanos a través de:</p>
            <div className="social-media-icons">
              <a href="https://www.instagram.com/longbeachrecordslatam/" className='Instagram' target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faInstagram} size="2x" />
              </a>
              <a href="https://open.spotify.com/user/d37g4zsrcmolmra9zkg22x5la" className='Spotify' target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faSpotify} size="2x" />
              </a>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
}

export default App;
